<template>
    <vue-loadmore
        :on-refresh="refresh_mr_bundles"
        :on-loadmore="mr_tasks.no_more ? null : loadmore_mr_bundles"
        :finished="false"
        style="width: 100%; direction: ltr"
        :pulling-text="$tr('Refresh')"
        :loosing-text="$tr('Refresh')"
        :refresh-text="`${$tr('Loading')}`"
        :loading-text="`${$tr('Loading')}`"
        :finished-text="$tr('Done')"
        :success-text="$tr('Done')"
    >
        <pull-to-refresh-banner ref="banner"></pull-to-refresh-banner>
        <mr-bundle-details-dialog
            @refresh="fetch_mr"
            :actions="actions"
            :dialog="bundle_details_dialog"
        />
        <v-fade-transition group>
            <v-card color="basil" :key="'main_card'" flat v-show="show_tasks">
                <v-card-text class="pt-0">
                    <v-row
                        class="px-2 pt-4 pb-2"
                        v-for="(bundle, index) in mr_tasks.bundles"
                        :key="index"
                    >
                        <v-card
                            width="100%"
                            @click="show_mr_bundle_details(bundle)"
                            :color="bundle_color_map[bundle.status]"
                        >
                            <v-card-text
                                class="d-flex align-center justify-center"
                                style="color: #001b87bb; font-weight: 600"
                            >
                                {{
                                    `${$tr("From")} ${new Date(
                                        bundle.bundle_start_date
                                    ).toLocaleDateString()} -- ${$tr("To")} -- ${new Date(
                                        bundle.bundle_end_date
                                    ).toLocaleDateString()}`
                                }}
                            </v-card-text>
                        </v-card>
                    </v-row>
                </v-card-text>
            </v-card>

            <template>
                <v-container
                    v-show="show_skeleton"
                    :key="'skeleton'"
                    class="px-4"
                    fluid
                >
                    <v-row class="mb-2" :key="'skeleton-1'">
                        <v-skeleton-loader
                            :loading="true"
                            width="100%"
                            type="table-heading"
                        ></v-skeleton-loader>
                    </v-row>
                    <v-row class="mb-2" :key="'skeleton-2'">
                        <v-skeleton-loader
                            :loading="true"
                            width="100%"
                            type="table-heading"
                        ></v-skeleton-loader>
                    </v-row>
                    <v-row class="mb-2" :key="'skeleton-3'">
                        <v-skeleton-loader
                            :loading="true"
                            width="100%"
                            type="table-heading"
                        ></v-skeleton-loader>
                    </v-row>
                </v-container>
            </template>
        </v-fade-transition>

        <v-container v-show="mr_tasks.no_more && show_tasks">
            <template>
                <v-divider :key="'end-divider'"></v-divider>
                <v-row
                    :key="'end-text'"
                    class="d-flex pt-4 pb-4 align-center justify-center"
                >
                    <span>{{$tr("Thats All")}}</span>
                </v-row>
            </template>
        </v-container>
    </vue-loadmore>
</template>

<script>
import Api from "@/api/api/index.js";

export default {
    props: {
        actions: null,
        employee: null,
    },
    data() {
        return {
            bundle_color_map: {
                1: "primary lighten-3",
                2: "yellow lighten-3",
                3: "#ffffff",
            },
            bundle_details_dialog: {
                show: false,
                bundle: null,
            },
            mr_tasks: {
                bundles: [],
                take: 20,
                work_days: [],
                skip: 0,
                no_more: false,
            },
            tab: 0,
            fetching_tasks: false,
            show_tasks: false,
            show_skeleton: false,
        };
    },
    methods: {
        show_mr_bundle_details(bundle) {
            console.log("mr work days", this.mr_tasks.work_days);
            this.$set(this.bundle_details_dialog, "bundle", bundle);
            this.$set(
                this.bundle_details_dialog,
                "work_days",
                this.mr_tasks.work_days
            );
            this.$set(this.bundle_details_dialog, "show", true);
        },

        async fetch_mr(clear = true) {
            console.log("loading .....");
            try {
                if (clear) {
                    this.mr_tasks.bundles.splice(0);
                    this.mr_tasks.skip = 0;
                    this.mr_tasks.no_more = false;
                } else {
                    this.mr_tasks.skip += this.mr_tasks.take;
                }
                const response = await Api.post(
                    "employee/fetch/bundles/paginate",
                    {
                        user_id: this.employee.user_id,
                        take: this.mr_tasks.take,
                        skip: this.mr_tasks.skip,
                    },
                    {
                        dontCache: true,
                    }
                );
                const bundles = response.data.results.bundles || [];
                this.mr_tasks.work_days = response.data.results.work_days;
                if ((bundles.length || 0) < this.mr_tasks.take) {
                    this.mr_tasks.no_more = true;
                }
                this.mr_tasks.bundles.push(...bundles);
            } catch (err) {
                console.log(err);
                const notification = {
                    msg:
                        err.response?.data?.error?.msg ||
                        "Error, please try again later",
                    color: "error",
                };
                this.$store.dispatch("user/notify", notification);
            }
        },

async set_loading() {
    this.show_tasks = false;
    this.show_skeleton = false;
    await this.$sleep(200);
    this.show_skeleton = true;
    // await this.$sleep(200);
},
async clear_loading() {
    this.show_skeleton = false;
    this.show_tasks = false;
    await this.$sleep(100);
    this.show_tasks = true;
},
        async refresh_mr_bundles(done, show_banner=false) {
            await this.set_loading();
            await this.fetch_mr(true);
            await this.clear_loading();
            if(show_banner){
                this.$refs.banner.view_banner();
            }
            done?.();
        },
        async loadmore_mr_bundles(done) {
            await this.fetch_mr(false);
            done?.();
        },
    },
    components: {
        CardView: () =>
            import(
                /* webpackChunkName: "card_view" */
                "../card_view.vue"
            ),
        MrBundleDetailsDialog: () =>
            import(
                /* webpackChunkName: "bundle_details_dialog" */
                "../mr_bundle_details_dialog.vue"
            ),
        PullToRefreshBanner: () => import("../pullToRefreshBanner/Index.vue"),

    },
};
</script>

<style>
</style>